import { FC, useCallback, useState } from "react";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

interface BannerCloseButtonProps {
  handleCloseBanner: () => void;
  color: string;
}

const BannerCloseButton: FC<BannerCloseButtonProps> = ({
  handleCloseBanner,
  color,
}) => {
  const [closed, setClosed] = useState(false);

  const handleClick = useCallback(() => {
    setClosed(true);
    handleCloseBanner();
  }, [handleCloseBanner]);

  return (
    <>
      {!closed ? (
        <IconButton size="small" sx={{ color: color }} onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      ) : (
        <CircularProgress style={{ color: color }} size={18} sx={{ m: 2 }} />
      )}
    </>
  );
};
export default BannerCloseButton;
