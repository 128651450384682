"use client";
import { FC, useCallback, useState } from "react";

import backend from "../../utils/backend";
import { API_ROUTES } from "../../config/routes";

import Box from "@mui/material/Box";

import BannerCloseButton from "./BannerCloseButton";

import BannerIcons from "components/Banners/BannerIcons";

type TopBannerStickyProps = {
  bannerView: {
    id: number;
    colorFont: string;
    colorBackground: string;
    icon: string;
    iconColor: string;
    iconAlt: string;
    contents: string;
    linkColor: string;
    userOuid: string;
  };
};

const TopBanner: FC<TopBannerStickyProps> = ({ bannerView }) => {
  const linkStyle = `<style>.topBanner a {color: ${bannerView?.linkColor}!important;}</style>`;
  const [visible, setVisible] = useState(true);

  const handleClose = useCallback(() => {
    setVisible(false);

    const banner = document.getElementById("hidden-top-banner");
    if (banner) {
      banner.style.height = "0";
    }

    const megaMenu = document.getElementById("mega-menu");
    if (megaMenu) {
      megaMenu.style.top = "177px";
    }

    const subMenu = document.getElementById("sub-menu");
    if (subMenu) {
      subMenu.style.top = "177px";
    }

    const d = {
      bannerLanguageId: bannerView.id,
      ouid: bannerView.userOuid,
    };

    backend
      .post(API_ROUTES.CLOSE_BANNER, d)
      .then((response) => {
        if (response.status === 200) {
          console.info("succes close banner");
        } else {
          console.error(response);
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  }, [bannerView]);

  return bannerView && visible ? (
    <Box
      color={bannerView.colorFont}
      bgcolor={bannerView.colorBackground}
      className="topBanner"
    >
      {bannerView.icon && (
        <Box mr={"4px"}>
          <BannerIcons
            iconName={bannerView.icon}
            alt={bannerView.iconAlt}
            color={bannerView.iconColor}
          />
        </Box>
      )}
      <Box
        dangerouslySetInnerHTML={{
          __html: linkStyle + bannerView.contents,
        }}
      />
      <Box style={{ position: "absolute", top: "4", right: "0" }}>
        <BannerCloseButton
          color={bannerView.iconColor}
          handleCloseBanner={handleClose}
        />
      </Box>
    </Box>
  ) : (
    <></>
  );
};
export default TopBanner;
