import { FC, memo } from "react";

import Image from "next/image";
import Link from "next/link";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import NavTabPanel from "components/Layout/Header/DesktopNav/NavTabPanel";

import { cleanUpImageUrl } from "utils/ImagesUrl";

import { colorPrimary100 } from "theme/colors";

type SubMenuProps = {
  value: number | false;
  i: number;
  handleMouseLeave: () => void;
  heightTopBanner?: number | null;
  el: Item;
};

const SubMenu: FC<SubMenuProps> = ({
  value,
  i,
  handleMouseLeave,
  heightTopBanner,
  el,
}) => {
  return (
    <NavTabPanel
      value={value}
      index={i}
      onMouseLeave={() => handleMouseLeave()}
    >
      <Grid
        id="sub-menu"
        container
        direction={"row"}
        justifyContent="center"
        alignItems="center"
        className={"fcx-megamenu with-img"}
        sx={{ top: (heightTopBanner ? heightTopBanner + 177 : 177) + "px" }}
        style={{ zIndex: "2" }}
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
            alignItems="flex-start"
          >
            {el.linkedMenu?.map((e, i) => {
              return (
                <ListItem key={i}>
                  <Link
                    href={e.actionUrl}
                    className={"fcx-menu-item p-align-center"}
                    title={e.label}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        color: "#1BA8CE",
                        "&:hover": {
                          color: "#164194 !important",
                          backgroundColor: colorPrimary100,
                        },
                      }}
                    >
                      {e.type === "LINK" && e.image && e.image !== "" && (
                        <Box
                          sx={{
                            height: 180,
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <Image
                            src={`${
                              process.env.NEXT_PUBLIC_ASSETS_URL
                            }${cleanUpImageUrl(e.image)}`}
                            alt={e.imageAlt ? e.imageAlt : ""}
                            fill
                          />
                        </Box>
                      )}
                      <Typography variant="subtitle2">{e.label}</Typography>
                    </Stack>
                  </Link>
                </ListItem>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </NavTabPanel>
  );
};
export default memo(SubMenu);
