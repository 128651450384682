import { FC, memo } from "react";

import Image from "next/image";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

const HeaderLogo: FC = () => {
  return (
    <Link href="/">
      <Box
        sx={{
          width: { xs: "147px", lg: "183px" },
          height: { xs: "45px", lg: "56px" },
          position: "relative",
        }}
      >
        <Image
          priority
          src="/zg-lv-logo.svg"
          alt="Zdrowegeny.pl part of longevity + Logo"
          title="Zdrowegeny.pl"
          fill
        />
      </Box>
    </Link>
  );
};
export default memo(HeaderLogo);
