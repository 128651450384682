"use client";

import { FC, useCallback, useEffect, useState } from "react";

import { secString } from "../../../utils/sec";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import TopBanner from "components/Banners/TopBanner";
import HeaderBtnCall from "components/Layout/Header/HeaderBtnCall";
import HeaderButtons from "components/Layout/Header/HeaderButtons";
import HeaderLogo from "components/Layout/Header/HeaderLogo";
import HeaderMobileMenuButton from "components/Layout/Header/HeaderMobileMenuButton";
import HeaderMobileNav from "components/Layout/Header/HeaderMobileNav";
import HeaderNav from "components/Layout/Header/HeaderNav";
import MainSearcherWithAutocomplete from "components/MainSearcherWithAutocomplete";

import useClientHeight from "utils/useClientHeight";

import { colorGray100, colorPrimary700 } from "theme/colors";

interface HeaderProps {
  page: any;
}

const Header: FC<HeaderProps> = ({ page }): JSX.Element => {
  const topBannerView = page?.topBannerView;
  const [mobileMenu, setMobileMenu] = useState(false);
  const [heightTopBanner, refTopBannerBox] = useClientHeight();

  const [value, setValue] = useState<number | false>(false);
  const [valueSubMenu, setValueSubMenu] = useState<number | false>(false);

  const openMobileMenu = useCallback(() => {
    !mobileMenu
      ? document.body.classList.add("menu-open")
      : document.body.classList.remove("menu-open");
    setMobileMenu(!mobileMenu);
  }, [mobileMenu]);

  // Zwijanie menu w zaleznosci od scrollowania START //
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [appBarStyle, setAppBarStyle] = useState({
    transition: "max-height 0.2s ease-in-out",
    maxHeight: "unset",
  });

  useEffect(() => {
    if (topBannerView && heightTopBanner) {
      setAppBarStyle((prevState) => ({
        ...prevState,
        maxHeight:
          (mobileMenu ? 136 + heightTopBanner : 164 + heightTopBanner) + "px",
      }));
    }
  }, [topBannerView, heightTopBanner, mobileMenu]);

  const controlNavbar = useCallback(() => {
    const heightTopBannerValue =
      topBannerView && heightTopBanner ? heightTopBanner : 0;
    let mainBox: HTMLElement | null = null;

    if (document) {
      mainBox = document.getElementById("fcx-subheader");
    }

    if (window) {
      if (window.scrollY > lastScrollY && window.scrollY > 1) {
        // Przewijanie w dół
        setShowHeader(false);
        setAppBarStyle({
          ...appBarStyle,
          maxHeight: mobileMenu
            ? 68 + heightTopBannerValue + "px"
            : 87.5 + heightTopBannerValue + "px",
        });
        if (mainBox) {
          mainBox.classList.add("shrink");
        }
      } else {
        // Przewijanie w górę
        setShowHeader(true);
        setAppBarStyle({
          ...appBarStyle,
          maxHeight: mobileMenu
            ? 136 + heightTopBannerValue + "px"
            : 164 + heightTopBannerValue + "px",
        });
        if (mainBox) {
          mainBox.classList.remove("shrink");
        }
      }
      setLastScrollY(window.scrollY);
    }
  }, [heightTopBanner, appBarStyle, lastScrollY, mobileMenu, topBannerView]);

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY, appBarStyle, controlNavbar]);

  const handleCloseMenu = () => {
    setValue(false);
    setValueSubMenu(false);
  };

  return (
    <>
      <AppBar
        elevation={0}
        style={{ backgroundColor: colorPrimary700, ...appBarStyle }}
      >
        {topBannerView && (
          <Box id={"top-banner"} ref={refTopBannerBox}>
            <TopBanner bannerView={topBannerView} />
          </Box>
        )}
        <Container
          id="fcx-top-header"
          maxWidth="xl"
          sx={{
            paddingTop: { lg: "15px", xs: "10px" },
            paddingBottom: { lg: "17px", xs: "10px" },
          }}
        >
          <Grid
            container
            flex="true"
            justifyContent="space-between"
            maxWidth="100%"
            alignItems="center"
          >
            <Grid item sx={{ display: { lg: "none", xs: "block" } }}>
              <HeaderMobileMenuButton openMobileMenu={openMobileMenu} />
              {mobileMenu && (
                <HeaderMobileNav
                  page={page}
                  open={mobileMenu}
                  handleClose={() => setMobileMenu(!mobileMenu)}
                />
              )}
            </Grid>
            <Grid item xs={"auto"} onMouseOver={handleCloseMenu}>
              <HeaderLogo />
            </Grid>
            <Grid
              item
              xs={true}
              sx={{ display: { lg: "block", xs: "none" }, px: 20 }}
              onMouseOver={handleCloseMenu}
            >
              <MainSearcherWithAutocomplete
                path={page?.pageData?.url}
                q={secString(page?.query["q"])}
              />
            </Grid>
            <Grid item xs={"auto"} display="flex" alignItems="center">
              <HeaderButtons
                ouid={page?.cartView?.ouid}
                cartItemCount={page?.cartView?.cartItemCount}
              />
            </Grid>
          </Grid>
        </Container>
        <Container
          id="fcx-menu-header"
          disableGutters={true}
          maxWidth="xl"
          sx={{
            paddingY: "10px",
            borderTop: `1px solid ${colorGray100}`,
            display: showHeader ? "block" : "none", // Kontroluje widoczność opartą o stan
          }}
        >
          <Grid
            container
            alignItems="center"
            flex="true"
            sx={{ justifyContent: { lg: "space-between", xs: "center" } }}
          >
            <Grid
              item
              xs={true}
              sx={{
                display: { lg: "none", xs: "block" },
                px: 3,
              }}
            >
              <MainSearcherWithAutocomplete
                path={page?.pageData?.url}
                q={secString(page?.query["q"])}
              />
            </Grid>
            <Grid item sx={{ display: { lg: "block", xs: "none" } }}>
              <HeaderNav
                menuItems={page?.menuItems || []}
                pageType={page.pageType}
                value={value}
                valueSubMenu={valueSubMenu}
                setValue={setValue}
                setValueSubMenu={setValueSubMenu}
                heightTopBanner={heightTopBanner}
              />
            </Grid>
            <HeaderBtnCall />
          </Grid>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
