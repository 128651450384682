import React, { FC, memo } from "react";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

import MegaItem from "components/Layout/Header/DesktopNav/MegaItem";
import NavTabPanel from "components/Layout/Header/DesktopNav/NavTabPanel";
import MegaSubMenu from "components/Layout/Header/DesktopNav/MegaSubMenu";

type MegaMenu = {
  value: number | false;
  i: number;
  handleMouseLeave: () => void;
  heightTopBanner?: number | null;
  el: Item;
  setValueSubMenu: (value: number) => void;
  valueSubMenu: number | false;
};

const MegaMenu: FC<MegaMenu> = ({
  value,
  i,
  handleMouseLeave,
  heightTopBanner,
  el,
  setValueSubMenu,
  valueSubMenu,
}) => {
  const handleMouseOverSubMenu = (i) => {
    if (valueSubMenu !== i) {
      setValueSubMenu(i);
    }
  };

  return (
    <NavTabPanel
      value={value}
      index={i}
      key={i}
      onMouseLeave={() => handleMouseLeave()}
    >
      <Grid
        id={"mega-menu"}
        container
        direction={"row"}
        className={"fcx-megamenu"}
        sx={{ top: (heightTopBanner ? heightTopBanner + 177 : 177) + "px" }}
        style={{ zIndex: "2" }}
      >
        <Grid item xs={3}>
          <List className={"border-r"}>
            {el.linkedMenu?.map((e, i) => {
              return (
                <MegaItem
                  key={i}
                  label={e.label}
                  actionUrl={e.actionUrl}
                  handleMouseOverSubMenu={() => handleMouseOverSubMenu(i)}
                />
              );
            })}
          </List>
        </Grid>
        {el.linkedMenu?.map((e, i) => {
          return (
            <MegaSubMenu key={i} valueSubMenu={valueSubMenu} i={i} e={e} />
          );
        })}
      </Grid>
    </NavTabPanel>
  );
};

export default memo(MegaMenu);
